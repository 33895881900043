import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Experience from 'components/Experience';
import Education from 'components/Education';
import Skills from 'components/Skills';
import { PageProps } from 'gatsby';

const ResumePage: React.FC<PageProps> = (   pageProps ) => {
  return (
    <Layout location={pageProps.location}>
    <SEO title="Resume" />
    <Experience />
    <hr />
    {/* <Education />
    <hr />
    <Skills /> */}
  </Layout>
)};

export default ResumePage;
